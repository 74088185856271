import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: process.env.DATADOG_SITE,
    service: 'c3',
    env: process.env.NODE_ENV,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });

  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    env: process.env.NODE_ENV,
    version: process.env.REACT_APP_VERSION,
    service: 'c3',
    site: process.env.DATADOG_SITE,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['log', 'error', 'info'],
    sampleRate: 100,
  });
}
